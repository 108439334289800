import styled from "styled-components";

const LogoImage = styled.img`
  width: auto;
  height: 4rem;
`;

const Logo = () => {
  return <LogoImage src="/images/logo.png" alt="AFTERMATH" />;
};

export default Logo;
