import React from "react";
import Link from "next/link";
import styled from "styled-components";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Logo from "./Logo";

const LogoContainer = styled.div`
  width: 240px;
  text-align: center;
  padding: 30px 50px 10px;
  font-size: 14px;

  img {
    width: 100%;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobileMenu = ({ open, onClose }: Props) => {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <List component="nav">
        <Link href="/allowlist">
          <ListItem button component="a" onClick={onClose}>
            <ListItemText primary="Allowlist" />
          </ListItem>
        </Link>
        <Link href="/loja">
          <ListItem button component="a" onClick={onClose}>
            <ListItemText primary="Loja" />
          </ListItem>
        </Link>
        <ListItem button component="a" href="https://rprp.city/discord">
          <ListItemText primary="Discord" />
        </ListItem>
        {/*<Link href="/codigo-conduta">*/}
        {/*  <ListItem button component="a" onClick={onClose}>*/}
        {/*    <ListItemText primary="Código de Conduta" />*/}
        {/*  </ListItem>*/}
        {/*</Link>*/}
        <Link href="https://tv.rprp.city/">
          <ListItem button component="a" onClick={onClose}>
            <ListItemText primary="RPRP TV" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          component="a"
          href="https://www.instagram.com/rprp.city"
        >
          <ListItemIcon>
            <InstagramIcon />
          </ListItemIcon>
          <ListItemText primary="Instagram" />
        </ListItem>
        <ListItem button component="a" href="https://twitter.com/rprp_city">
          <ListItemIcon>
            <TwitterIcon />
          </ListItemIcon>
          <ListItemText primary="Twitter" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="https://www.youtube.com/user/Denissnider"
        >
          <ListItemIcon>
            <YouTubeIcon />
          </ListItemIcon>
          <ListItemText primary="YouTube" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MobileMenu;
